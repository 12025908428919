<template>
  <div class="seeMore">
    <div class="seeMore-center">
      <div class="report">
        <Box>
          <div style="width: 100%">
            <div @click="back" class="back-button" style="float: right"></div>
            <div class="header">
              <div class="title"><span class="text">无人机实名认证</span></div>
            </div>
            <div class="body">
               <!-- 详情 -->
               <div style="color: #b6d6ff; font-size: 20px">无人驾驶航空器信息</div>
              <el-form
                ref="edit_form"
                :rules="rules.edit_form"
                :model="edit_form"
                class="linear-form vertical edit-form"
              >
                <div style="width: 100%" class="grap">
                  <el-form-item label="登记标识" prop="djbs">
                    <el-input
                      v-model="edit_form.djbs"
                      placeholder="请输入登记标识"
                      clearable
                    />
                  </el-form-item>
                  <el-form-item label="生产厂商名称" prop="csmc">
                    <el-input
                      v-model="edit_form.csmc"
                      placeholder="请输入生产厂商名称"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="产品型号" prop="cpxh">
                    <el-input
                      v-model="edit_form.cpxh"
                      placeholder="请输入产品型号"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="产品名称" prop="cpmc">
                    <el-input
                      v-model="edit_form.cpmc"
                      placeholder="请输入产品名称"
                      clearable
                    />
                  </el-form-item>

                  <el-form-item label="产品类别" prop="cplb">
                    <el-input
                      v-model="edit_form.cplb"
                      placeholder="请输入产品类别"
                      clearable
                    />
                  </el-form-item>
                
                <el-form-item label="产品类型" prop="cplx">
                    <el-input
                      v-model="edit_form.cplx"
                      placeholder="请输入产品类型"
                      clearable
                    />
                </el-form-item>
                <el-form-item label="空机重量(kg)" prop="kjzl">
                   <el-input
                      v-model="edit_form.kjzl"
                      placeholder="请输入空机重量"
                      clearable
                    />
                  </el-form-item>
                  <el-form-item label="最大起飞重量(kg)" prop="zdqfzl">
                    <el-input
                      v-model="edit_form.zdqfzl"
                      placeholder="最大起飞重量"
                      clearable
                    />
                  </el-form-item>
                  <el-form-item label="产品序列号" prop="cpxlh">
                    <el-input
                      v-model="edit_form.cpxlh"
                      placeholder="请输入产品序列号"
                      clearable
                    />
                  </el-form-item>
                  <el-form-item label="使用用途" prop="syyt">
                    <el-input
                      v-model="edit_form.syyt"
                      placeholder="请输入使用用途"
                      clearable
                    />
                  </el-form-item>
              </div>
              </el-form>


                <div style="color: #b6d6ff; font-size: 20px;">无人机照片</div>
                <div style="display: flex; margin-top: 20px">
                  <div style="width: 50%">
                    <div style="color: #b6d6ff; font-size: 20px;margin-bottom: 20px;display: flex;align-items: center;">设备正面照
                      <div v-if="imageUrl1" class="linear-btn" style="margin-left: 50px" @click="() => imageUrl1=null">清除</div>
                    </div>
                    <el-upload
                class="avatar-uploader"
                action=""
                accept=".png,.webp,.img,.jpg,.jpeg"
                :auto-upload="false"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :on-change="beforeAvatarUpload"
                >
                    <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
                    <div v-else class="linear-btn">+</div>
                </el-upload>
                  </div>
                  
                <div style="width: 50%">
                  <div style="color: #b6d6ff; font-size: 20px;margin-bottom: 20px;display: flex;align-items: center;">
                    <div>设备序列号照片</div>
                    <div v-if="imageUrl2" class="linear-btn" style="margin-left: 50px" @click="() => imageUrl2=null">清除</div>
                  </div>
                  <el-upload
                class="avatar-uploader"
                action=""
                accept=".png,.webp,.img,.jpg,.jpeg"
                :auto-upload="false"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :on-change="beforeAvatarUpload2"
                >
                    <img v-if="imageUrl2" :src="imageUrl2" class="avatar">
                    <div v-else class="linear-btn">+</div>
                </el-upload>
                </div>
                
                </div>
            </div>
          </div>
        </Box>
      </div>
      <div class="Side" >
        <div class="box" >
          <span class="iconfont icon-baocun" title="保存"></span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Box from "@/components/platform/common/box";
import Table from "@/components/common/table";
export default {
  components: {
    Box,
    Table
  },
  data() {
    return {
      edit_form: {
        djbs: null,
        csmc: null,
        cpxh: null,
        cpmc: null,
        cplb: null,
        cplx: null,
        kjzl: null,
        zdqfzl: null,
        cpxlh: null,
        syyt: null,
      },
      rules: {
        edit_form: {
          djbs: [
            { required: true, message: '请输入登记标识', trigger: 'blur' }
          ],
          csmc: [
            { required: true, message: '请输入生产厂商名称', trigger: 'blur' }
          ],
          cpxh: [
            { required: true, message: '请输入产品型号', trigger: 'blur' }
          ],
          cpmc: [
            { required: true, message: '请输入产品名称', trigger: 'blur' }
          ],
          cplb: [
            { required: true, message: '请输入产品类别', trigger: 'blur' }
          ],
          cplx: [
            { required: true, message: '请输入产品类型', trigger: 'blur' }
          ],
          kjzl: [
            { required: true, message: '请输入空机重量', trigger: 'blur' }
          ],
          zdqfzl: [
            { required: true, message: '最大起飞重量', trigger: 'blur' }
          ],
          cpxlh: [
            { required: true, message: '请输入产品序列号', trigger: 'blur' }
          ],
          syyt: [
            { required: true, message: '请输入使用用途', trigger: 'blur' }
          ],
        },
      },
      imageUrl1:'',
      imageUrl2:'',
    };
  },
  methods: {
    back() {
      this.$router.push(`/`)
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            if (!isPNG && !isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!')
                return false
            } else {
                this.imageUrl1 = URL.createObjectURL(file.raw);//赋值图片的url，用于图片回显功能
            }
        },
        beforeAvatarUpload2(file) {
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            // const isLt2M = file.raw.size / 1024 / 1024 < 0.5  //限制上传文件的大小
            if (!isPNG && !isJPG) {
                this.$message.error('上传图片只能是 JPG/PNG 格式!')
                return false
            } else {
                this.imageUrl2 = URL.createObjectURL(file.raw);//赋值图片的url，用于图片回显功能
            }
        }
  },
};
</script>

<style lang="scss" scoped>
.seeMore {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: auto;
  &-center {
    height: 991px;
    padding-top: 35px;
    display: flex;
    .report {
      width: 1288px;
      .header {
        padding-top: 4px;
        height: 36px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .title {
          width: 528px;
          height: 28px;
          background: url(~@/assets/platform/device/header.png) no-repeat;
          display: flex;
          justify-content: center;
          align-items: center;
          .text {
            color: #ffffff;
            font-size: 18px;
          }
        }
      }
      .body {
        padding: 24px 73px 0;
        height: 100%;

        .color {
          color: #b6d6ff;
          font-size: 18px;
        }
        .text {
          color: #b6d6ff;
          font-size: 16px;
          line-height: 30px;
        }
        .image {
          width: 80px;
          height: 70px;
        }
      }
    }
    .Side {
      padding-top: 310px;
      padding-left: 14px;
      .box {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        width: 52px;
        height: 52px;
        border: 1px solid #009aff;
        .iconfont {
          cursor: pointer;
          font-size: 24px;
          color: #9fc4ff;
        }
      }
    }
  }
  .avatar {
      width: 300px;
      height: 300px;
    }
}
</style>
<style lang="scss" scoped>
::v-deep {
  .edit-form {
    .aa {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-form-item {
        width: calc(50% - 6px);
      }
    }

    .grap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .el-form-item {
        width: calc(50% - 60px);
      }
    }

    .linear-fields {
      width: 100%;
      margin-bottom: 20px;
      .el-form-item {
        width: 100%;
      }
    }
  }
}
</style>
<style lang="scss">
.report .cpt-service-table .table-bd .table-tr {
  height: 80px;
}
.taskapprove {
  .button {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    padding: 28px 55px;
  }
}
.back-button {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: url(~@/assets/platform/message/task/details/closure.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.seeMore .seeMore-center .report .body {
  overflow-y: auto;
}
.seeMore .cpt-warning-slot-box {
  border: none;
  margin: 0;
  height: 70px;
}
</style>
